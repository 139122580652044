import "./VideoItemDisplay.css";
import VideoPlayer from "../components/VideoPlayer";
import Transcode from "../components/Transcode";
import VideoDownload from "../components/VideoDownload";

function VideoItemDisplay({videoFile, updateOnComplete}) {
    return (
        <div>
            <h1>{<VideoDownload videoFile={videoFile}></VideoDownload>}</h1>
            <VideoPlayer videoFile={videoFile}></VideoPlayer>
            <Transcode videoFile={videoFile} updateOnComplete={updateOnComplete}></Transcode>
        </div>
    );
}

export default VideoItemDisplay;