import {useState, useEffect} from 'react';
import axios from "axios";
import "./VideoPlayer.css";
import invalidVideoImage from "./images/invalid.png";
import {getCookie} from "../services/cookies.js";

function VideoPlayer({videoFile}) {
    const token = getCookie();

    const [videoSrc, setVideoSrc] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [validType, setValidType] = useState(true);

    useEffect(() => {
        setThumbnail(null);
        setVideoSrc(null);
        setValidType(true);
        
        if (videoFile) {
            const fetchThumbnail = async () => {
                try {
                    const res = await axios.post(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_DATA_PORT}/data/download`, {fileKey: videoFile.thumbnailFileKey}, {headers: {"Authorization": `Bearer ${token}`}});
                    setThumbnail(res.data.url);
                }
                catch (err) {
                    console.error(err);
                }
            };
        
            const fetchVideo = async () => {
                try {
                    const res = await axios.post(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_DATA_PORT}/data/download`, {fileKey: videoFile.fileKey}, {headers: {"Authorization": `Bearer ${token}`}});
                    setVideoSrc(res.data.url);
                }
                catch (err) {
                    console.error(err);
                }
            };
            fetchThumbnail();
            fetchVideo();
        }

    }, [videoFile]);

    return (
        <div className="video-player">
            <video src={videoSrc} className="video-src" onError={() => setValidType(false)} poster={validType ? thumbnail : invalidVideoImage} controls></video>
        </div>
    );
}

export default VideoPlayer;