import "./Upload.css";
import axios from "axios";
import uploadButtonImage from "./images/uploadbutton.png";
import {getCookie} from "../services/cookies.js";

function Upload({updateOnComplete}) {
	const token = getCookie();

	const handleUpload = async (e) => {
		try {
			const video = e.target.files[0];
			const videoRes = await axios.post(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_DATA_PORT}/data/upload`, {fileName: video.name}, {headers: {"Authorization": `Bearer ${token}`}});
			await axios.put(videoRes.data.url, video, {headers: {'Content-Type': video.type}});
			e.target.value = "";
			updateOnComplete();
		}
		catch (err) {
			console.error(err);
		}
	};

	return (
	<div>
		<label className="upload-button" htmlFor="upload">
		<img className="upload-button-image" src={uploadButtonImage} alt="uploadbutton"></img>
		</label>
		<input type="file" id="upload" onChange={handleUpload} hidden={true}></input>
	</div>
	);
}

export default Upload;
