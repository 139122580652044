import LoginForm from "../components/LoginForm";
import "./Login.css"

function Login() {
    return (
        <div className="login-form">
            <LoginForm></LoginForm>
        </div>
    );
}

export default Login;