import "./LoginForm.css";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {login, register} from "../services/cognito.js";
import {setCookie} from "../services/cookies.js";

function LoginForm() {
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [isSignUp, setIsSignUp] = useState(false);
	const navigate = useNavigate();

	const handleLogin = async () => {
		try {
			const token = await login(username, password);
			setCookie(token);
			navigate("/home");
		}
		catch (err) {
			console.error(err);
		}
	};

	const handleRegister = async () => {
		try {
			await register(username, password, email);
			setIsSignUp(false);
		}
		catch (err) {
			console.error(err);
		}
	}
  
  	return (
		<div className="login-container">
			
			{isSignUp ? 
			(
				<div>
					<div className="login-header">Register</div>
					<div className="register-email">
						<input type="text" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email"></input>
					</div>
					<div className="login-username">
						<input type="text" onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Username"></input>
					</div>
					<div className="login-password">
						<input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Password"></input>
					</div>
					<button onClick={handleRegister} className="register-button" type="submit" disabled={!email || !username || !password}>Register</button>
				</div>
			)
			:
			(
				<div>
					<div className="login-header">Login</div>
					<div className="login-username">
						<input type="text" onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Username"></input>
					</div>
					<div className="login-password">
						<input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Password"></input>
					</div>
					<button onClick={handleLogin} className="login-button" type="submit" disabled={!username || !password}>Login</button>
					<button onClick={() => setIsSignUp(true)} className="register-button" type="button">Sign Up</button>
				</div>
			)}
		</div>
  );
}

export default LoginForm;