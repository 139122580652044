import "./Home.css";
import {useState, useEffect} from "react";
import axios from "axios";
import Upload from "../components/Upload";
import VideoItem from "../components/VideoItem";
import VideoItemDisplay from "../components/VideoItemDisplay";
import {getCookie, deleteCookie} from "../services/cookies.js";
import {useNavigate} from "react-router-dom";

function Home() {
    const token = getCookie();
    const navigate = useNavigate();
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_DATA_PORT}/data/videos`, {headers: {"Authorization": `Bearer ${token}`}})
            .then((res) => {
                const sortedVideos = res.data.sort((a, b) => b.id - a.id);
                setVideos(sortedVideos);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [updateTrigger]);

    useEffect(() => {
        if (!videos.includes(selectedVideo)) {
            setSelectedVideo(null);
        }
    }, [videos, selectedVideo]);

    const updateOnComplete = () => {
        setTimeout(() => setUpdateTrigger(!updateTrigger), 3000);
    };

    const handleLogout = () => {
        deleteCookie();
        navigate("/");
    }

    return (
        <div className="home-container">
            <div className="home-video-menu-container">
                <button onClick={handleLogout}>Logout</button>
                <h1>Videos</h1>
                <Upload updateOnComplete={updateOnComplete}></Upload>
                    <div className="home-video-list-container">
                    <div className="home-video-list">
                        <ul>
                            {videos.map((video, index) => (
                                <li key={index}>
                                    <VideoItem videoFile={video} updateOnComplete={updateOnComplete} updateSelected={() => setSelectedVideo(video)} isSelected={video === selectedVideo}></VideoItem>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="home-video-player-container">
                <div className="home-video-player">
                    {selectedVideo ? (
                        <VideoItemDisplay videoFile={selectedVideo} updateOnComplete={updateOnComplete}></VideoItemDisplay>
                    ) 
                    : 
                    (
                        <h1>Select a video</h1>
                    )}
                </div> 
            </div>
        </div>
    );
}

export default Home;