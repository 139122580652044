import "./VideoItem.css";
import VideoDelete from './VideoDelete.js';
import "./VideoDelete.css";

function VideoItem({videoFile, updateOnComplete, updateSelected, isSelected}) {
    return (
        <div className={`video-item-container ${isSelected ? "selected" : ""}`} onClick={updateSelected}>
            <div className="video-item">
                <div className="video-item-text">
                    <p>{videoFile.fileName}</p>
                </div>
                <div className="video-item-delete">
                    <VideoDelete videoFile={videoFile} updateOnComplete={updateOnComplete}></VideoDelete>
                </div>
            </div>
        </div>    
    );
}

export default VideoItem;