import axios from "axios";
import "./VideoDownload.css";
import {getCookie} from "../services/cookies.js";

function VideoDownload({videoFile}) {
	const token = getCookie();

	const handleFileDownload = async () => {
		try {
			const res = await axios.post(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_DATA_PORT}/data/download`, {fileKey: videoFile.fileKey}, {headers: {"Authorization": `Bearer ${token}`}});
			const video = await axios.get(res.data.url, {responseType: "blob"});
			const link = document.createElement("a");
			link.href = URL.createObjectURL(new Blob([video.data]));
			link.download = videoFile.fileName;
			link.click();
		}
		catch (err) {
			console.error(err);
		}
	};

	return (
	<div>
		<label className="download-button" htmlFor="download">{videoFile.fileName}</label>
		<button onClick={handleFileDownload} id="download" name="download" hidden={true}></button>
	</div>
	);
}

export default VideoDownload;