import axios from "axios";
import "./VideoDelete.css";
import deleteButtonImage from "./images/deletebutton.png";
import {getCookie} from "../services/cookies.js";

function VideoDelete({videoFile, updateOnComplete}) {
	const token = getCookie();

	const handleDelete = async () => {
		try {
			const res = await axios.delete(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_DATA_PORT}/data/video/${videoFile.fileName}`, {headers: {"Authorization": `Bearer ${token}`}});
			if (res.status === 200) {
				updateOnComplete();
			}
		}
		catch (err) {
			console.error(err);
		}
	};

	return (
	<div className="delete-button">
		<button onClick={handleDelete}>
		<img className="delete-button-image" src={deleteButtonImage} alt="deletebutton"></img>
		</button>
	</div>

	);
}

export default VideoDelete;