import { CognitoIdentityProviderClient, InitiateAuthCommand, SignUpCommand } from "@aws-sdk/client-cognito-identity-provider";

const cognitoClient = new CognitoIdentityProviderClient({region: process.env.REACT_APP_AWS_REGION});
const clientId  = process.env.REACT_APP_COGNITO_CLIENT_ID;

export const login = async (username, password) => {
    try {
        const {AuthenticationResult} = await cognitoClient.send(
            new InitiateAuthCommand({
                AuthFlow: "USER_PASSWORD_AUTH",
                AuthParameters: {
                  USERNAME: username,
                  PASSWORD: password
                },
                ClientId: clientId
            })
        );
        return AuthenticationResult.IdToken;
    } 
    catch (err) {
        console.error(err);
    }
};
  
export const register = async (username, password, email) => {
    try {
        const response = await cognitoClient.send(
            new SignUpCommand({
                ClientId: clientId,
                Username: username,
                Password: password,
                UserAttributes: [{ Name: "email", Value: email }]
            })
        );
        return response;
    }
    catch (err) {
        console.error(err);
    }
};