export const setCookie = (token) => {
    document.cookie = `token=${token}; path=/; max-age=3600; secure; samesite=strict`;
};

export const getCookie = () => {
    const nameEQ = "token=";
    const cookiesArray = document.cookie.split("; ");
    for (let cookie of cookiesArray) {
        if (cookie.startsWith(nameEQ)) {
            return cookie.substring(nameEQ.length);
        }
    }
    return null;
}

export const deleteCookie = () => {
    document.cookie = "token=; Max-Age=-99999999; path=/; secure; samesite=strict";
}