import "./Transcode.css";
import {useState, useEffect} from "react";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import {getCookie} from "../services/cookies.js";

function Transcode({videoFile, updateOnComplete}) {
    const token = getCookie();
    const [selectedFormat, setSelectedFormat] = useState("");
    const [progress, setProgress] = useState(0);
    const [jobId, setJobId] = useState(null);

    const formatList = [
        "3gp",
        "avi",
        "flv",
        "mkv",
        "mov",
        "mp4",
        "mpeg",
        "mpg",
        "ts",
        "webm",
        "wmv"
    ];

    const handleTranscode = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_TRANSCODE_PORT}/transcode/video`, {fileName: videoFile.fileName, format: selectedFormat}, {headers: {"Authorization": `Bearer ${token}`}});
            setJobId(res.data.jobId);
        }
        catch (err) {
            console.error(err);
        };
    };

    useEffect(() => {
        if (jobId) {
            const eventSource = new EventSource(`${process.env.REACT_APP_SERVER_DNS}:${process.env.REACT_APP_SERVER_PROGRESS_PORT}/progress/job/${jobId}`);
            eventSource.onerror = () => {
                console.log("Connection lost. Attempting to reconnect...");
            }
            eventSource.onmessage = async (event) => {
                const data = JSON.parse(event.data);
                setProgress(data.progress);
                if (data.status === "done" || data.status === "failed") {
                    eventSource.close();
                    setJobId(null);
                    setProgress(0);
                    setSelectedFormat("");
                    updateOnComplete();
                }
            };
            return () => {
                eventSource.close();
            };
        }
    }, [jobId]);

    return (
        <div className="transcode-container">
            {jobId ? (
                <div className="transcode-progress-bar">
                <ProgressBar now={progress} label={`${progress}%`}/>
                </div>
            )
            :
            (
                <div>
                    <div>
                        <select className="transcode-format-select" onChange={(e) => setSelectedFormat(e.target.value)} value={selectedFormat}>
                            <option value="">Select a new format</option>
                            {formatList.map((format, index) => (
                                <option key={index} value={format}>{format}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <button className="transcode-button" onClick={handleTranscode} disabled={!selectedFormat}>Transcode</button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Transcode;